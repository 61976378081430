<template>
  <v-dialog v-model="dialog" max-width="1200px" :persistent="true">
    <v-card style="min-height:168px; height: 100%;">
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('title.realizado_detalhamento') }}
      </v-card-title>
      <v-card-subtitle v-if="indAcoesVerbasIguais"
       style="word-break: break-word; width: 97%; font-size: 20px; color: black; padding-top: 12px;">
        {{ $t('message.calculo_crescimento_verbas_iguais', { nomesCategoriasAgrupadas: nomesCategoriasAgrupadas }) }}
      </v-card-subtitle>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-simple-table class="tabela">
                <tbody>
                  <tr>
                    <td style="border: none"></td>
                    <th colspan="5" style="background-color:rgba(0, 0, 0, 0.09);">
                      {{ $t('subtitle.periodo_atual', { data: periodoAtual}) }}
                    </th>
                    <th colspan="5" style="background-color:rgba(0, 0, 0, 0.09);">
                      {{ $t('subtitle.periodo_anterior', { data: periodoAnterior }) }}
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $tc('label.fornecedor') }}</th>
                    <th>{{ $tc('label.valor_entrada') }}</th>
                    <th>{{ $tc('label.devolucao') }}</th>
                    <th>{{ $tc('label.ipi') }}</th>
                    <th>{{ $tc('label.icms') }}</th>
                    <th>{{ $tc('label.pis_cofins') }}</th>
                    <th>{{ $tc('label.valor_entrada') }}</th>
                    <th>{{ $tc('label.devolucao') }}</th>
                    <th>{{ $tc('label.ipi') }}</th>
                    <th>{{ $tc('label.icms') }}</th>
                    <th>{{ $tc('label.pis_cofins') }}</th>
                  </tr>
                  <tr v-for="item in metas" :key="item.id_contrato">
                    <td>{{ item.fornecedor }}</td>
                    <td>{{ valToMoney(item.total) }}</td>
                    <td>{{ valToMoney(item.devolucao * -1) }}</td>
                    <td>{{ valToMoney(item.ipi) }}</td>
                    <td>{{ valToMoney(item.icms) }}</td>
                    <td>{{ valToMoney(item.pis_cofins) }}</td>
                    <td>{{ valToMoney(item.total_periodo_anterior) }}</td>
                    <td>{{ valToMoney(item.devolucao_periodo_anterior * -1) }}</td>
                    <td>{{ valToMoney(item.ipi_periodo_anterior) }}</td>
                    <td>{{ valToMoney(item.icms_periodo_anterior) }}</td>
                    <td>{{ valToMoney(item.pis_cofins_periodo_anterior) }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $tc('label.total') }}</th>
                    <th>{{ valToMoney(calculos.soma_total) }}</th>
                    <th>{{ valToMoney(calculos.soma_devolucao) }}</th>
                    <th>{{ valToMoney(calculos.soma_ipi) }}</th>
                    <th>{{ valToMoney(calculos.soma_icms) }}</th>
                    <th>{{ valToMoney(calculos.soma_pis_cofins) }}</th>
                    <th>{{ valToMoney(calculos.soma_total_periodo_anterior) }}</th>
                    <th>{{ valToMoney(calculos.soma_devolucao_periodo_anterior) }}</th>
                    <th>{{ valToMoney(calculos.soma_ipi_periodo_anterior) }}</th>
                    <th>{{ valToMoney(calculos.soma_icms_periodo_anterior) }}</th>
                    <th>{{ valToMoney(calculos.soma_pis_cofins_periodo_anterior) }}</th>
                  </tr>
                  <tr>
                    <th>{{ $tc('label.entrada_com_desconto') }}</th>
                    <th colspan="5">{{ valToMoney(calculos.total_com_descontos) }}</th>
                    <th colspan="5">{{ valToMoney(calculos.total_periodo_anterior_com_descontos) }}</th>
                  </tr>
                  <tr>
                    <th>{{ $tc('label.realizado_crescimento') }}</th>
                    <th>{{ valorCrescimento }}</th>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="close">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { convertToPercent, convertToMoney } from '@/common/functions/numeric';
import { buscarRealizadoDetalhamentoCategoriaAgrupada } from '@/common/resources/apuracao-contrato';

export default {
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
      dialog: false,
      metas: [],
      calculos: {},
      indAcoesVerbasIguais: false,
      listaDeCategorias: [],
      nomesCategoriasAgrupadas: '',
    };
  },
  computed: {
    valorCrescimento() {
      let valorCalculado = 100;
      if (this.calculos.total_periodo_anterior_com_descontos !== 0) {
        valorCalculado = (((this.calculos.total_com_descontos * 100) / this.calculos.total_periodo_anterior_com_descontos) - 100).toFixed(2);
      }
      return parseFloat(valorCalculado) === 0 ? ' - ' : convertToPercent(valorCalculado);
    },
    periodoAtual() {
      return `${this.calculos.data_inicio} - ${this.calculos.data_fim}`;
    },
    periodoAnterior() {
      return this.calculos.data_inicio_periodo_anterior === this.calculos.data_fim_periodo_anterior
        ? this.calculos.data_inicio_periodo_anterior
        : `${this.calculos.data_inicio_periodo_anterior} - ${this.calculos.data_fim_periodo_anterior}`;
    },
  },
  methods: {
    convertToPercent,
    convertToMoney,
    open(idApuracao, indAcoesVerbasIguais) {
      const promises = [];
      if (indAcoesVerbasIguais) {
        this.indAcoesVerbasIguais = indAcoesVerbasIguais;
        promises.push(this.buscarRealizadoDetalhamentoCategoriaAgrupada(Number(idApuracao)));
      }
      promises.push(this.buscarRealizadoDetalhamento(Number(idApuracao)));
      Promise.all(promises)
        .then(() => {
          this.dialog = true;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    close() {
      this.indAcoesVerbasIguais = false;
      this.listaDeCategorias = [];
      this.dialog = false;
    },
    buscarRealizadoDetalhamento(idApuracao) {
      this.resource.buscarRealizadoDetalhamento({ idApuracao }).then((res) => {
        const { calculos, metas } = { ...res.data };
        this.metas = metas;
        this.calculos = calculos;
      });
    },
    buscarRealizadoDetalhamentoCategoriaAgrupada(idApuracao) {
      const params = {
        idApuracao,
      };
      buscarRealizadoDetalhamentoCategoriaAgrupada(params, this.$resource).then((res) => {
        this.listaDeCategorias = res.data;
        const nomesCategoriasAgrupadas = this.listaDeCategorias.map((item) => item.nome_categoria).join(';');
        this.nomesCategoriasAgrupadas = nomesCategoriasAgrupadas;
      });
    },
    valToMoney(value) {
      if (value === 0) {
        return ' - ';
      }
      return convertToMoney(value);
    },
  },
};
</script>
<style>
.tabela th, .tabela tr, .tabela td {
  padding:5px !important;
  text-align: center !important;
  border:solid 1px rgb(202, 200, 200);
  white-space: nowrap;
}
</style>
