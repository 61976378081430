<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('title.nds_geradas', 1) }}
      </v-card-title>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="registros"
                item-key="nd"
                :options.sync="pagination"
                :server-items-length="totalPage"
                class="elevation-0"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:[`item.periodo`]="{ item }">
                  {{ formatarPeriodo(item)}}
                </template>
                <template v-slot:[`item.valorNd`]="{ item }">
                  {{ getMoney(item.valorNd)}}
                </template>
                <template v-slot:[`item.acaoExternaId`]="{ item }">
                  <v-tooltip v-if="item.acaoExternaId" bottom>
                    <template v-if="item.acaoExternaId.length>10"  v-slot:activator="{ on }">
                      <v-title v-on="on">{{ item.acaoExternaId.substring(0, 10) + '...' }}</v-title>
                    </template>
                    <template v-else v-slot:activator="{}">
                      <v-title>{{ item.acaoExternaId }}</v-title>
                    </template>
                    <span>{{ item.acaoExternaId }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import moment from 'moment';
import { getMoney } from '@/common/functions/helpers';

export default {
  name: 'DialogApuracaoContratoNdsGeradas',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      valor: '',
      registros: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      totalPage: 10,
      headers: [
        {
          text: this.$tc('label.periodo', 2), value: 'periodo', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
        { text: this.$tc('label.nd', 2), value: 'nd', sortable: false },
        { text: this.$tc('label.acao_externa_id', 2), value: 'acaoExternaId', sortable: false },
        {
          text: this.$tc('label.cnpj', 2), value: 'cnpj', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
        { text: this.$tc('label.razao_social', 2), value: 'razaoSocial', sortable: false },
        {
          text: this.$tc('label.valor_nd', 2), value: 'valorNd', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
        { text: this.$tc('label.gerado_em', 2), value: 'geradoEm', sortable: false },
        { text: this.$tc('label.gerado_por', 2), value: 'geradoPor', sortable: false },
      ],
    };
  },
  methods: {
    getMoney,
    open(ndsGeradas) {
      this.registros = ndsGeradas;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
    formatarPeriodo(item) {
      const dataInicioFormatada = moment(item.dataInicio, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      const dataFimFormatada = moment(item.dataFim, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      if (dataInicioFormatada !== dataFimFormatada) {
        return `${dataInicioFormatada} a ${dataFimFormatada}`;
      }
      return dataInicioFormatada;
    },
  },
};
</script>
<style lang="scss">
