<template>
  <div class="pt-8">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $tc("label.resumo_apuracao", 1) }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="ml-0">
      <v-card>
        <v-card-text>
          <v-table density="compact" theme="dark">
            <thead>
              <tr>
                <th :width="tamanhoCampoPeriodo" class="text-left">{{ $t('label.periodo') }}</th>
                <th width="140px" class="text-left">{{ $t('label.verba_aplicada') }}</th>
                <th width="150px" class="text-left">{{ `${$t('label.valor_base')} (${$t('label.original')})` }}</th>
                <th width="150px" class="text-left">{{ `${$t('label.valor_base')} (${$t('label.novo')})` }}</th>
                <th width="160px" class="text-left">{{ `${$t('label.recebimento')} (${$t('label.original')})` }}</th>
                <th width="150px" class="text-left">{{ `${$t('label.recebimento')} (${$t('label.novo')})` }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Array.of(apuracao)" :key="item.id">
                <td class="text-left">{{ periodoApuracao }}</td>
                <td class="text-left">{{ formatarVerba(item) }}</td>
                <td class="text-left">{{ getMoney(item.valorBaseOriginal) }}</td>
                <td v-if="indAlteradoManualmente" style="color: blue" class="text-left">{{ getMoney(item.valorBase) }}</td>
                <td v-else class="text-left">{{ getMoney(item.valorBase) }}</td>
                <td class="text-left">{{ getMoney(item.recebimentoAtual) }}</td>
                <td v-if="indAlteradoManualmente" style="color: blue" class="text-left">{{ getMoney(item.recebimentoDefinido) }}</td>
                <td v-else class="text-left">{{ getMoney(item.recebimentoDefinido) }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import moment from 'moment';
import { getMoney, getPercent } from '@/common/functions/helpers';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'RecebimentoParcialResumo',
  components: {
    Confirm,
  },
  props: {
    apuracao: {},
  },
  watch: {
  },
  data() {
    return {
      periodoApuracao: null,
    };
  },
  computed: {
    tamanhoCampoPeriodo() {
      if (this.isApuracaoMensal) {
        return `${this.periodoApuracao.length * 14}px`;
      }
      return `${this.periodoApuracao.length * 7}px`;
    },
    isApuracaoMensal() {
      return this.apuracao.periodicidade === 'MENSAL';
    },
    indAlteradoManualmente() {
      return this.apuracao.fornecedores.some((fornecedor) => fornecedor.indAlteradoManualmente);
    },
  },
  methods: {
    getMoney,
    getPercent,
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    formatarPeriodo() {
      if (this.isApuracaoMensal) {
        return moment(this.apuracao.dataInicio, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      }
      return `${moment(this.apuracao.dataInicio, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase()}
        a ${moment(this.apuracao.dataFim, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase()}`;
    },
  },
  beforeMount() {
    this.periodoApuracao = this.formatarPeriodo();
  },
};
</script>
