<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card style="min-height:168px; height: 100%;">
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('title.calculo_apuracao_agrupada') }}
      </v-card-title>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-data-table
               :headers="headers"
               :items="listaDeCategorias"
               class="elevation-1"
               hide-default-footer
               >
                 <template v-slot:[`item.valor_nota`]="{ item }">
                       <span>
                         {{ getMoney(item.valor_nota) }}
                       </span>
                 </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="close">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getMoney } from '@/common/functions/helpers';
import { buscarRealizadoDetalhamentoCategoriaAgrupada } from '@/common/resources/apuracao-contrato';

export default {
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
      headers: [
        { text: 'Categoria', value: 'nome_categoria' },
        { text: 'Realizado', value: 'valor_nota' },
      ],
      listaDeCategorias: [],
      dialog: false,
    };
  },
  methods: {
    getMoney,
    open(idApuracao, dataInicio) {
      this.buscarRealizadoDetalhamento(Number(idApuracao), dataInicio);
    },
    close() {
      this.dialog = false;
    },
    buscarRealizadoDetalhamento(idApuracao, dataInicio) {
      const params = {
        idApuracao,
      };
      if (dataInicio !== null) {
        params.dataInicio = dataInicio;
      }
      buscarRealizadoDetalhamentoCategoriaAgrupada(params, this.$resource).then((res) => {
        this.listaDeCategorias = res.data;
        this.dialog = true;
      });
    },
  },
};
</script>
