<template>
    <v-card tile style="background: #eee" :key="apuracao.id">
      <v-toolbar dense dark color="primary" elevate-on-scroll>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $tc('label.alterar_valor_por_cnpj', 1).toUpperCase() }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text style="font-size: 1.1em" class="font-weight-black mt-1">
        <v-container fluid>
          <v-row>
            <v-col cols="1" sm="1" class="text-left">
              <span>{{ `${$tc('title.contrato', 1)}:` }}</span>
            </v-col>
            <v-col cols="11" sm="11" class="text-left">
              {{ cabecalhoContrato }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1" sm="1" class="text-left">
              <span>{{ `${$tc('title.apuracao', 1)}:` }}</span>
            </v-col>
            <v-col cols="11" sm="11" class="text-left">
              {{ cabecalhoApuracao }}
            </v-col>
          </v-row>
          <recebimento-parcial-resumo
            :apuracao="apuracao">
          </recebimento-parcial-resumo>
          <recebimento-parcial-tabela
            ref="recebimentoParcialTabela"
            :apuracao="apuracao"
            :somente-leitura="somenteLeitura"
            @AlterarFornecedorSelecionado="alterarFornecedorSelecionado">
          </recebimento-parcial-tabela>
          <recebimento-parcial-fornecedor
            ref="recebimentoParcialFornecedor"
            v-if="habilitaFormRecebimentoParcial"
            :somente-leitura="!somenteLeitura"
            :apuracao="apuracao"
            :fornecedor="fornecedorSelecionado"
            :dta-inicio="dtaInicio"
            :dta-fim="dtaFim"
            @RecarregarApuracao="recarregarApuracao"
            @DesabilitaFormRecebimentoParcial="desabilitaFormRecebimentoParcial">
          </recebimento-parcial-fornecedor>
          <arquivos-fornecedor-tabela
            ref="asrquivosFornecedorTabela"
            v-if="somenteLeitura"
            :apuracao="apuracao"
            :somente-leitura="somenteLeitura">
          </arquivos-fornecedor-tabela>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-10 px-9">
        <v-spacer></v-spacer>
        <v-btn
          v-if="indFinalizarApuracao"
          color="primary darken-1"
          @click.native="finalizar"
        >{{ $tc('label.finalizar_apuracao', 1) }}</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import RecebimentoParcialResumo from '@/spa/apuracao-contrato/recebimento-parcial/RecebimentoParcialResumo';
import RecebimentoParcialFornecedor from '@/spa/apuracao-contrato/recebimento-parcial/RecebimentoParcialFornecedor';
import RecebimentoParcialTabela from '@/spa/apuracao-contrato/recebimento-parcial/RecebimentoParcialTabela';
import ArquivosFornecedorTabela from '@/spa/apuracao-contrato/recebimento-parcial/ArquivosFornecedorTabela';

export default {
  components: {
    RecebimentoParcialResumo,
    RecebimentoParcialFornecedor,
    RecebimentoParcialTabela,
    ArquivosFornecedorTabela,
  },
  props: {
    apuracao: {},
    somenteLeitura: Boolean,
    dtaInicio: String,
    dtaFim: String,
    finalizarApuracao: Function,
  },
  data() {
    return {
      fornecedorSelecionado: {},
      habilitaFormRecebimentoParcial: false,
    };
  },
  computed: {
    labels() {
      const map = new Map();
      map.set('descontos', ` - ${this.$tc('label.desconto', 2)}: `);
      map.set('VALOR_LIQUIDO', ` - ${this.$tc('label.valor_net')}`);
      map.set('VALOR_BRUTO', ` - ${this.$tc('label.valor_bruto')}`);
      map.set('SELLIN', ` - ${this.$tc('label.sellin')}`);
      map.set('SELLOUT', ` - ${this.$tc('label.sellout')}`);
      map.set('DEVOLUCOES', `${this.$tc('label.devolucao', 2)}`);
      map.set('ICMS', `${this.$tc('label.icms')}`);
      map.set('IPI', `${this.$tc('label.ipi')}`);
      map.set('PIS_COFINS', `${this.$tc('label.pis_cofins')}`);

      return map;
    },
    cabecalhoContrato() {
      return ''.concat(this.apuracao.idContrato)
        .concat(' - ')
        .concat(this.apuracao.origemContrato)
        .concat(this.labels.get(this.apuracao.calculoBonificacao))
        .concat(this.labels.get(this.apuracao.baseCalculo))
        .concat(this.labels.get('descontos'))
        .concat(this.formatarDescontos(this.apuracao.descontos, this.labels));
    },
    cabecalhoApuracao() {
      return ''.concat(` ${this.apuracao.idApuracao}`)
        .concat(' - ')
        .concat(this.apuracao.acaoTipo)
        .concat(' - ')
        .concat(this.apuracao.categoria);
    },
    indFinalizarApuracao() {
      return !this.somenteLeitura && this.apuracao.indValorAlterado;
    },
  },
  methods: {
    close() {
      this.$emit('FecharModalRecebimentoParcial');
    },
    formatarDescontos(descontos, labels) {
      return descontos ? descontos.split(';').map((desconto) => labels.get(desconto)).join('; ') : '';
    },
    valorRecebimentoAlterado() {
      this.$refs.adicionarRecebimentoParcial.validarForms();
    },
    alterarFornecedorSelecionado(fornecedor) {
      this.fornecedorSelecionado = fornecedor;
      this.habilitaFormRecebimentoParcial = true;
    },
    desabilitaFormRecebimentoParcial() {
      this.habilitaFormRecebimentoParcial = false;
    },
    recarregarApuracao() {
      this.$emit('RecarregarApuracao');
    },
    finalizar() {
      this.finalizarApuracao(this.apuracao);
    },
  },
};
</script>
