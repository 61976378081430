<template>
  <div class="pt-6">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $tc("title.altera_cnpj_selecionado", 1) }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-text>
            <v-form
              ref="form"
              lazy-validation
              autocomplete="off"
            >
              <v-row>
                <v-col cols="12" sm="1">
                  <v-text-field
                    :label="$t('label.cod_fornecedor')"
                    id="cod-fornecedor"
                    :value="fornecedor.codFornecedor"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    :label="$t('label.razao_social')"
                    id="id-razao-social"
                    :value="fornecedor.razaoSocial"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" style="white-space: nowrap">
                  <input-money
                    class="d-inline-flex"
                    ref="valorBaseInput"
                    :label="$t('label.novo_valor_base')"
                    id="id-valor-base"
                    v-model="novoValorBase"
                    :hint="getMoney(fornecedor.valorBase)"
                    :persistent-hint="true"/>
                  <v-btn
                    icon
                    :disabled="false"
                    @click="recalcularFornecedores">
                    <v-icon>refresh</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="2">
                  <input-money
                    :id="'id-valor-novo-nd' + item.id"
                    :label="$t('label.novo_valor_nd')"
                    v-model="novoValorNd"
                    :hint="getMoney(fornecedor.valorNdPrevisto)"
                    :persistent-hint="true"
                    class="v-text-field"
                  ></input-money>
                </v-col>
                <v-col sm="2">
                  <v-textarea
                    id="justificativa-recebimento-parcial"
                    v-model="justificativaAlteracao"
                    rows="1"
                    auto-grow
                    required
                    :rules="[rules.required]"
                    :label="`${$t('label.justificativa')} *`"
                    :counter="500"
                    maxlength="500"
                  >
                  </v-textarea>
                </v-col>
                <v-col sm="3">
                  <v-switch
                    :label="$t('label.impressao_nd_com_historico_notas')"
                    color="primary"
                    v-model="indImpressaoNdComHistorico"
                    class="mt-3"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" sm="10">
              <div v-if="!!fornecedor">
                <v-card>
                  <v-container >
                    <apuracao-fornecedor-arquivo
                      :habilitar-exclusao="somenteLeitura"
                      :fornecedor="fornecedor">
                    </apuracao-fornecedor-arquivo>
                  </v-container>
                </v-card>
              </div>
            </v-col>
              <v-col cols="12" sm="2">
              <v-btn @click="cancelar">{{ $t("label.cancelar") }}</v-btn>
              <v-btn
                color="primary"
                @click="salvarAlteracaoValorFornecedor"
                >{{ $t("label.salvar") }}
              </v-btn>
            </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from 'moment';
import { formatlastDayOfTheMonth } from '@/common/functions/date-utils';
import InputMoney from '@/shared-components/inputs/InputMoney';
import { getMoney, getPercent } from '@/common/functions/helpers';
import ApuracaoFornecedorArquivo from '@/spa/apuracao-contrato/recebimento-parcial/ApuracaoFornecedorArquivo';

export default {
  components: {
    InputMoney,
    ApuracaoFornecedorArquivo,
  },
  props: {
    apuracao: {},
    fornecedor: {},
    somenteLeitura: Boolean,
    dtaInicio: String,
    dtaFim: String,
  },
  watch: {
  },
  data() {
    return {
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorMaiorZero: (value) => value > 0 || this.$t('message.valor_maior_zero'),
      },
      justificativaAlteracao: null,
      indImpressaoNdComHistorico: false,
      novoValorBase: 0,
      novoValorNd: 0,
    };
  },
  computed: {
    isVerbaFixa() {
      return this.apuracao.formaBonificacao === 'FIXO';
    },
  },
  methods: {
    getMoney,
    getPercent,
    cancelar() {
      this.$emit('DesabilitaFormRecebimentoParcial');
    },
    salvarAlteracaoValorFornecedor() {
      if (!this.$refs.form.validate()) return;
      const fornecedorResumo = this.fornecedorResumo(this.fornecedor);
      fornecedorResumo.indValorNdPrevistoAlterado = true;
      if (this.novoValorBase > 0) {
        fornecedorResumo.indValorNdPrevistoAlterado = false;
        fornecedorResumo.valorBase = this.novoValorBase;
      }
      fornecedorResumo.valorNdPrevisto = this.novoValorNd;
      const { idAcao } = this.fornecedor;
      fornecedorResumo.dtaInicio = moment(this.dtaInicio, 'YYYY-MM').format('YYYY-MM-DD');
      fornecedorResumo.dtaFim = formatlastDayOfTheMonth(moment(this.dtaFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      this.apuracaoAcaoResource.atualizarFornecedorResumo({ idAcao }, fornecedorResumo).then((resposta) => {
        this.setNovosValores(resposta.data);
        this.$emit('RecarregarApuracao');
        this.$emit('DesabilitaFormRecebimentoParcial');
      }).catch((err) => { this.$error(this, err); });
    },
    recalcularFornecedores() {
      if (this.novoValorBase <= 0) return;
      const fornecedorResumo = this.fornecedorResumo(this.fornecedor);
      fornecedorResumo.valorBase = this.novoValorBase;
      const { idAcao } = this.fornecedor;
      this.apuracaoAcaoResource.recalcularFornecedores({ idAcao }, fornecedorResumo).then((resposta) => {
        const fornecedores = resposta.data;
        const fornecedor = fornecedores.find((f) => f.idFornecedor === fornecedorResumo.idFornecedor);
        this.novoValorNd = fornecedor.valorNdPrevisto;
      }).catch((err) => { this.$error(this, err); });
    },
    fornecedorResumo(fornecedor) {
      return {
        idApuracao: fornecedor.idApuracao,
        idFornecedor: fornecedor.idFornecedor,
        valorBase: fornecedor.valorBase,
        valorBaseOriginal: fornecedor.valorBaseOriginal,
        coeficiente: fornecedor.coeficiente,
        valorNdGerado: fornecedor.valorNdGerado,
        valorNdPrevistoOriginal: fornecedor.valorNdPrevistoOriginal,
        verba: this.apuracao.verba,
        template: this.apuracao.template,
        justificativaAlteracao: this.justificativaAlteracao,
        indImpressaoNdComHistorico: this.indImpressaoNdComHistorico,
      };
    },
    setNovosValores(fornecedores) {
      this.apuracao.fornecedores.forEach((fornecedor) => {
        fornecedores.forEach((f) => {
          if (fornecedor.idFornecedor === f.idFornecedor) {
            fornecedor.indAlteradoManualmente = f.indAlteradoManualmente;
            fornecedor.valorNdPrevisto = f.valorNdPrevisto;
            if (!f.indValorNdPrevistoAlterado) {
              fornecedor.valorBase = f.valorBase;
            }
          }
        });
      });
    },
  },
};
</script>
