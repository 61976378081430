<template>
  <div class="pt-10">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $t("title.lista_cnpj_apuracao") }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="apuracao.fornecedores"
              class="elevation-0 my-3"
              :single-expand="true"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              hide-default-footer
              disable-pagination
              :items-per-page="-1"
            >
              <template v-slot:[`item.codFornecedor`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ item.codFornecedor }} </span>
                <span v-else> {{ item.codFornecedor }} </span>
              </template>
              <template v-slot:[`item.razaoSocial`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ item.razaoSocial }} </span>
                <span v-else> {{ item.razaoSocial }} </span>
              </template>
              <template v-slot:[`item.cnpj`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ item.cnpj }} </span>
                <span v-else> {{ item.cnpj }} </span>
              </template>
              <template v-slot:[`item.categoria`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ item.categoria }} </span>
                <span v-else> {{ item.categoria }} </span>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue">{{ getMoney(item.total) }} </span>
                <span v-else>{{ getMoney(item.total) }} </span>
              </template>
              <template class="justify-center" v-slot:[`item.devolucoes`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ formatarValor(item.devolucoes) }} </span>
                <span v-else> {{ formatarValor(item.devolucoes) }} </span>
              </template>

              <template v-slot:[`item.icms`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ formatarValor(item.icms) }} </span>
                <span v-else> {{ formatarValor(item.icms) }} </span>
              </template>

              <template v-slot:[`item.ipi`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ formatarValor(item.ipi) }} </span>
                <span v-else> {{ formatarValor(item.ipi) }} </span>
              </template>

              <template v-slot:[`item.pisCofins`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ formatarValor(item.pisCofins) }} </span>
                <span v-else> {{ formatarValor(item.pisCofins) }} </span>
              </template>

              <template v-slot:[`item.valorBase`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue"> {{ getMoney(item.valorBase) }} </span>
                <span v-else> {{ getMoney(item.valorBase) }} </span>
              </template>

              <template v-slot:[`item.valorNdPrevisto`]="{ item }">
                <span v-if="item.indAlteradoManualmente" style="color: blue">{{ getMoney(item.valorNdPrevisto) }}</span>
                <span v-else>{{ getMoney(item.valorNdPrevisto) }}</span>
              </template>

              <template
                class="justify-center"
                v-slot:[`item.alterarValor`]="{ item }">
                <v-btn width="108%" color="primary pa-6 px-2" @click="alterarValor(item)">
                {{ $t("label.alterar_valor")}}
                </v-btn>
              </template>

              <template v-slot:[`item.indImpressaoNdComHistorico`]="{ item }">
                <span>
                  {{ item.indImpressaoNdComHistorico ? $t('label.sim') : $t('label.nao') }}
                </span>
              </template>

              <template v-slot:[`item.justificativa`]="{ item }">
                <v-tooltip bottom v-if="item.indAlteradoManualmente">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                    >
                    description
                    </v-icon>
                  </template>
                  <span>{{ getJustificativa(item) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getMoney } from '@/common/functions/helpers';

export default {
  name: 'RecebimentoParcialTabela',
  components: {
  },
  data() {
    return {
      headers: [
        {
          text: this.$tc('label.cod_fornecedor'), value: 'codFornecedor', class: 'nowrap', color: 'blue',
        },
        { text: this.$tc('label.razao_social'), value: 'razaoSocial' },
        {
          text: this.$tc('label.cnpj'), value: 'cnpj', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.categoria'), value: 'categoria', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_de_entrada'), value: 'total', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.devolucao', 2), value: 'devolucoes', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.icms'), value: 'icms', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.ipi'), value: 'ipi', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.pis_cofins'), value: 'pisCofins', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base'), value: 'valorBase', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_nd_previsto'), value: 'valorNdPrevisto', cellClass: 'nowrap',
        },
      ],
    };
  },
  props: {
    apuracao: {},
    somenteLeitura: Boolean,
  },
  watch: {
  },
  computed: {
    habilitaAlterarValor() {
      return this.apuracao.statusApuracao === 'AGUARDANDO_APURACAO';
    },
  },
  methods: {
    getMoney,
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    alterarValor(fornecedor) {
      this.$emit('AlterarFornecedorSelecionado', fornecedor);
    },
    getJustificativa(item) {
      return item.justificativaAlteracaoValorBase || item.justificativaAlteracaoValorNdPrevisto || ' - ';
    },
  },
  mounted() {
    if (this.somenteLeitura) {
      this.headers.push({
        text: this.$tc('label.exibe_nota', 2), value: 'indImpressaoNdComHistorico', cellClass: 'nowrap', width: '6%',
      });
      this.headers.push({
        text: this.$tc('label.justificativa'), value: 'justificativa', cellClass: 'nowrap',
      });
    }
    if (this.habilitaAlterarValor && !this.somenteLeitura) {
      this.headers.push({
        text: '', value: 'alterarValor', cellClass: 'nowrap',
      });
    }
  },
};
</script>
